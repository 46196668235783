import { createRouter, createWebHashHistory } from 'vue-router'
import beforeEach from '@/router/filters/beforeEach'
import Layout from '@/components/layout/index.vue'

export const routes = [
  {
    path: '/login',
    meta: {
      isHidden: true,
    },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/redirect/:path(.*)',
    name: 'Redirect',
    meta: {
      isHidden: true,
    },
    component: () => import('@/views/redirect/index.vue')
  },
  {
    path: '/',
    component: Layout,
    redirect: '/links',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          title: '统计',
          icon: 'Histogram',
          keepAlive: true
        },
        component: () => import('@/views/dashboard/index.vue')
      },
      {
        path: '/links',
        name: 'Links',
        meta: {
          title: '链接管理',
          icon: 'Link',
          keepAlive: true
        },
        component: () => import('@/views/links/index.vue')
      },
      {
        path: '/link/operate/:id?',
        name: 'LinkOperate',
        meta: {
          title: '',
          icon: '',
          isHidden: true,
          keepAlive: true
        },
        component: () => import('@/views/links/components/form.vue')
      },
      {
        path: '/assets',
        name: 'Assets',
        meta: {
          title: '落地页管理',
          icon: 'Platform',
          keepAlive: true
        },
        component: () => import('@/views/assets/index.vue')
      },
      {
        path: '/user/account',
        name: 'Account',
        meta: {
          isHidden: true,
          keepAlive: false
        },
        component: () => import('@/views/user/account/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes
})
router.beforeEach(beforeEach)

export default router