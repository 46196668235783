<template>
  <div>
    <div class="logo-link">
      <img :style="{ display: menuCollapse ? '' : 'none' } " src="/favicon.png" alt="" width="40">
      <div class="logo-link--txt" :class="{ txtShow: !menuCollapse, txtHidden: menuCollapse }">
        <img src="@/assets/logo.png" alt="">
      </div>
    </div>
    <el-menu
        :default-active="currentRoute"
        background-color="#253545"
        active-text-color="#fff"
        @select="handleSelect"
        :collapse="menuCollapse"
    >
      <el-menu-item v-show="!item.meta.isHidden" v-for="item in menu" :index="item.path" :key="item.path" :route="item.path">
        <el-icon component="Edit">
          <component :is="item.meta.icon" />
        </el-icon>
        <span>{{ item.meta.title }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { routes } from '@/router'
import router from '@/router'
import { useRoute } from 'vue-router'
import { useGlobalStore } from '@/store'

const cRouter = useRoute()
const menu = ref(routes.filter(v => !v.meta || !v.meta.isHidden)[0].children)
const currentRoute = computed(() => cRouter.path)

const globalStore = useGlobalStore()
const menuCollapse = computed(() => globalStore.menuCollapse)

const handleSelect = (key) => {
  router.push({ path: key })
}
</script>

<style lang="scss">
.el-menu {
  border: none !important;
  z-index: 111;
}
.logo-link {
  width: 100%;
  padding: 24px 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 0 0;
  &--txt {
    height: 40px;
    width: 150px;
    overflow: hidden;
    img {
      height: 100%;
    }
  }
  &--txt.txtHidden {
    display: none;
  }
  &--txt.txtShow {
    animation: move 0.5s forwards;
  }
}
@keyframes move {
  0% {
    transform: translateX(-100%);
    opacity: 0;
    width: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    width: 150px;
  }
}
</style>