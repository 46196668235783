import request from '@/utils/request'

export function userData(data) {
  return request({
    url: '/auth/validate',
    method: 'post',
    data
  })
}

export function login(data) {
  return request({
    url: '/auth/login',
    method: 'POST',
    data
  })
}

export function getCode(data) {
  return request({
    url: '/auth/getcode',
    method: 'POST',
    data
  })
}

export function logout(data) {
  return request({
    url: '/auth/logout',
    method: 'POST',
    data
  })
}

// 绑定2fa
export function linktotp(data) {
  return request({
    url: '/user/linktotp',
    method: 'POST',
    data
  })
}

// 解绑2fa
export function unlinktotp(data) {
  return request({
    url: '/user/unlinktotp',
    method: 'POST',
    data
  })
}

export function getSystemData(flag, data) {
  return request({
    url: '/system/' + flag,
    method: 'post',
    data
  })
}

export function changeLinkStatus(data) {
  return request({
    url: '/link/updatestate',
    method: 'POST',
    data
  })
}

export function createLink(data) {
  return request({
    url: data.id ? '/link/updateinfo' : '/link/create',
    method: 'POST',
    data
  })
}

export function getLinkDetail(data) {
  return request({
    url: '/link/info',
    method: 'POST',
    data
  })
}