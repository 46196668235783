import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useGlobalStore = defineStore('global', () => {
  const userData = ref(null)
  const menuCollapse = ref(false)

  const setUserData = function(data) {
    userData.value = data
  }
  const setMenuCollapse = function(collapse) {
    menuCollapse.value = collapse
  }

  return {
    userData,
    menuCollapse,
    setUserData,
    setMenuCollapse
  }
})