<template>
  <router-view />
</template>

<script>
import { userData } from '@/api'
import { useGlobalStore } from '@/store'

export default {
  name: 'App',
  setup() {
    const store = useGlobalStore()
    const getUserData = async () => {
      try {
        const res = await userData({
          token: localStorage.getItem('token') || ''
        })
        store.setUserData(res)
      } catch (e) {
        console.error(e)
      }
    }
    getUserData()
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
