import { useTagsStore } from '@/store/tags'

export default function (to, from, next) {
  const tagsStore = useTagsStore()
  if (to.path === '/login') {
    tagsStore.clearVisitedTags()
    tagsStore.clearCachedTags()
  }
  if (to.path.startsWith('/link/operate')) {
    if (to.params.id) {
      to.meta.title = `${to.query.c === '1' ? '拷贝_' : '编辑_'}${to.query.name || ''}`
    } else {
      to.meta.title = '新建链接'
    }
  }
  if (to.meta.keepAlive) {
    tagsStore.addVisitedTags(to)
    tagsStore.addCachedTags(to)
  }
  next()
}